import { snakeCase } from 'change-case';

import {
  LocationFilterItem,
  LocationFilterViewType,
} from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { getSelectedCountriesFromAppliedFilters } from '@/core/components/MapFilters/LocationFilter/LocationFilter.utils';
import {
  dropdownTimeframeOptions,
  timeframeCustomTimeData,
  timeframeRadioButtonData,
} from '@/core/components/MapFilters/TimeframeFilter/timeframeRadioButtonData';
import { ReportImpact, ReportTypeCategory } from '@/core/interfaces/common';
import {
  AppliedFilters,
  AutoDateRange,
  KeywordsFilterItem,
  KeywordsFilters,
} from '@/core/interfaces/filters';

import { ReportsListData } from '@/features/Reports/interfaces';

import {
  getLocationFilterTypeFromItemType,
  getTransformedLocationFilterData,
  toBase64,
} from '@/utils/locations';
import { createGeoJSONStringForExport } from '@/utils/helpers';

import { caseConverter } from './case-converter';

const getImpactsFromAppliedFilters = (moreFilters: AppliedFilters['moreFilters']) => {
  return moreFilters
    ? Object.values(ReportImpact).reduce((acc, impactValue) => {
        const impact = moreFilters.find(({ value: filterValue }) => filterValue === impactValue);

        if (impact) {
          acc.push(impactValue);
        }

        return acc;
      }, [] as Array<ReportImpact>)
    : undefined;
};
const getReportTypesFromAppliedFilters = (moreFilters: AppliedFilters['moreFilters']) => {
  return moreFilters
    ? Object.values(ReportTypeCategory).reduce((acc, reportType) => {
        const report = moreFilters.find(({ value: filterValue }) => filterValue === reportType);

        if (report) {
          acc.push(reportType);
        }

        return acc;
      }, [] as Array<ReportTypeCategory>)
    : undefined;
};

const getTimeFrameFromAppliedFilters = (
  timeframe: AppliedFilters['timeframe']
): {
  autoDateRange: AutoDateRange | null;
  publishedSinceSeconds: number | null;
  startDate: string | null;
  endDate: string | null;
} => {
  let autoDateRange: AutoDateRange | null = null;
  let publishedSinceSeconds: number | null = null;
  let startDate: string | null = null;
  let endDate: string | null = null;
  const appliedFilter = timeframe?.[0];

  if (appliedFilter) {
    // Set state for event date custom timeframe
    if (typeof appliedFilter.value === 'string' && appliedFilter.value.includes(' - ')) {
      const filterDates = appliedFilter.value.split(' - ');

      autoDateRange = AutoDateRange.CUSTOM_TIMEFRAME;
      startDate = filterDates[0];
      endDate = filterDates[1];
      // Set state for event date predefined options
    } else if (dropdownTimeframeOptions.some(option => option.label === appliedFilter.label)) {
      // set new date filter for the days range (for example today or last 7 days)
      const eventTimeframeOption = dropdownTimeframeOptions.find(
        option => option.label === appliedFilter.label
      );

      // @ts-expect-error `apiValue` exists in the select option, it's not required in the select option type as it's needed only to get the value for the API
      autoDateRange = (eventTimeframeOption?.apiValue as AutoDateRange) || null;
    } else if (timeframeRadioButtonData.some(option => option.name === appliedFilter.label)) {
      const publicationDateOption = timeframeRadioButtonData.find(
        option => option.name === appliedFilter.label
      );

      publishedSinceSeconds = publicationDateOption!.value as number;

      // Set state for publication date custom option
    } else {
      const [customTimeFrameTime, customTimeFrameUnit] = (appliedFilter.value as string).split(' ');
      const publicationDateCustomTime = timeframeCustomTimeData.find(
        option => option.name === customTimeFrameUnit
      );

      if (publicationDateCustomTime) {
        publishedSinceSeconds =
          Number(customTimeFrameTime) * publicationDateCustomTime.amountInSeconds;
      }
    }
  }

  return {
    autoDateRange,
    startDate,
    endDate,
    publishedSinceSeconds,
  };
};

export const getLocationsFromAppliedFilters = (
  location: AppliedFilters['location']
): { countries: Array<number> | undefined; geofeatures: string | undefined } => {
  let countries: Array<number> = [];
  let geofeatures: string | undefined = undefined;

  if (location) {
    const transformedAppliedFilters = location.reduce((acc, appliedFilter) => {
      const itemValue = getTransformedLocationFilterData(String(appliedFilter.value));
      const filterType = getLocationFilterTypeFromItemType(itemValue.locationItemType);

      if (filterType === undefined) {
        return acc;
      }

      return [
        ...acc,
        {
          label: appliedFilter.label,
          value: appliedFilter.value,
          locationType: filterType,
          transformedLocationValue: itemValue,
        } as LocationFilterItem,
      ];
    }, [] as Array<LocationFilterItem>);

    countries = getSelectedCountriesFromAppliedFilters(transformedAppliedFilters).map(
      country => country.id
    );

    const geofeaturesList = transformedAppliedFilters
      .filter(filterItem =>
        [LocationFilterViewType.GEOFENCE, LocationFilterViewType.POI].includes(
          filterItem.locationType
        )
      )
      .map(filterItem =>
        // @ts-expect-error case converter type
        caseConverter(filterItem.transformedLocationValue.value, snakeCase)
      );

    // @ts-expect-error geofeaturesList has correct type
    geofeatures = toBase64(createGeoJSONStringForExport(geofeaturesList));
  }

  return {
    countries: countries.length ? countries : undefined,
    geofeatures,
  };
};

const getKeywordsFromAppliedFilters = (keywords: AppliedFilters['keywords']) => {
  const transformedKeywords = keywords?.map<KeywordsFilterItem>(({ value }) => {
    const [keyword, type] = (value as string).split(' - ');

    return {
      keyword,
      type,
    };
  });

  return transformedKeywords?.length
    ? toBase64(
        JSON.stringify({
          keywords: transformedKeywords,
        } satisfies KeywordsFilters)
      )
    : undefined;
};

export const transformAppliedFiltersToPostData = (filters: AppliedFilters): ReportsListData => {
  const { category, keywords, location, moreFilters, timeframe } = filters;

  const { autoDateRange, startDate, endDate, publishedSinceSeconds } =
    getTimeFrameFromAppliedFilters(timeframe);

  const { countries, geofeatures } = getLocationsFromAppliedFilters(location);

  const keywordsBase64JSON = getKeywordsFromAppliedFilters(keywords);

  return {
    autoDateRange,
    category: category ? category.map(({ value }) => value as number) : undefined,
    country: countries,
    eventEndDate: endDate,
    impact: getImpactsFromAppliedFilters(moreFilters),
    keyword: keywordsBase64JSON,
    publishedSinceSeconds,
    reportType: getReportTypesFromAppliedFilters(moreFilters),
    eventStartDate: startDate,
    geofeatureCoordinates: geofeatures,
  };
};
