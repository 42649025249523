import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { MapRef, Marker } from 'react-map-gl/maplibre';
import NextImage from 'next/image';
import { useSelector } from 'react-redux';

import { Map } from '@/core/components/Map';
import { Modal } from '@/core/components/Modal/Modal';
import { useAppDispatch } from '@/core/store/store';
import { Subheadline, Paragraph, Heading } from '@/core/components/Typography';
import { Icon } from '@/core/components/Icons';
import { useDecodedCategories } from '@/core/hooks/useDecodedCategories';
import {
  TIME_PUBLISHED_FORMAT,
  EVENT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '@/core/constants/time';
import { HEADER_HEIGHT } from '@/core/components/Layout/Layout.styles';
import { mapCategories } from '@/core/constants/reports';
import { MapGeofeatures } from '@/core/components/MapGeofeatures';
import { getAppliedFilterSelector } from '@/core/store/reducers/filtersSlice';
import { FilterType } from '@/core/interfaces/filters';
import { LocationFilterItemType } from '@/core/components/MapFilters/LocationFilter/LocationFilter.types';
import { useLoadMapIcons } from '@/core/hooks/useLoadMapIcons';
import { ReportSourceType } from '@/core/interfaces/common';

import { Source } from '@/features/Reports/components/Source';
import { MetaSection } from '@/features/Reports/components/MetaSection';
import { closeDetailsModal, markReportAsOpened } from '@/features/Reports/store/slices';
import { ReportScore } from '@/features/Reports/components/Source/Source.types';
import { getActiveReportDataSelector } from '@/features/Reports/store';
import { getReportCategoriesNames, getReportCountriesNames } from '@/features/Reports/helpers';
import { getAllGeofeaturesListSelector } from '@/features/Geofeatures/store';
import { GeofeatureItem } from '@/features/Geofeatures/interfaces';

import { getTimeAndDate } from '@/utils/helpers';
import { getTransformedLocationFilterData } from '@/utils/locations';

import {
  DetailsContainer,
  DetailsHeader,
  ReportTitle,
  Country,
  ReportContent,
  ReportContentColumn,
  ReportMap,
  HeaderTopBar,
  CloseIcon,
  TitleSource,
  ReportSourceTitle,
} from './ReportDetails.styles';
import { ReportDetailsProps } from './ReportDetails.types';

export const ReportDetails = ({ isOpen }: ReportDetailsProps) => {
  const report = useSelector(getActiveReportDataSelector);
  const geofeaturesList = useSelector(getAllGeofeaturesListSelector);
  const { colors } = useTheme();
  const dispatch = useAppDispatch();

  const categories = useDecodedCategories(getReportCategoriesNames(report?.category || []));

  const [mapRef, setMapRef] = useState<MapRef | null>(null);

  useLoadMapIcons(mapRef?.getMap() || null);

  const activeLocationFilter = useSelector(state =>
    getAppliedFilterSelector(state, FilterType.LOCATION)
  );

  const geofeaturesToDisplay = useMemo(
    () => [
      ...geofeaturesList.filter(geofeature => geofeature.visibleOnMap),
      ...activeLocationFilter.reduce((acc, filter) => {
        const filterValue = getTransformedLocationFilterData(filter.value as string);

        if (filterValue.locationItemType !== LocationFilterItemType.COUNTRY) {
          return [
            ...acc,
            {
              color: filterValue.value.properties.color,
              folderId: filterValue.value.properties.folder!,
              id: filterValue.value.properties.id,
              name: filterValue.value.properties.name,
              tags: filterValue.value.properties.tags,
              description: filterValue.value.properties.description,
              icon: filterValue.value.properties.icon,
              visibleOnMap: true,
              geoJSONData: filterValue.value,
            } satisfies GeofeatureItem,
          ];
        }

        return acc;
      }, [] as Array<GeofeatureItem>),
    ],
    [activeLocationFilter, geofeaturesList]
  );

  const closeModal = useCallback(() => {
    dispatch(closeDetailsModal());
  }, [dispatch]);

  const dateFormat = useMemo(() => {
    const eventStartDate = report?.eventDate
      ? getTimeAndDate({
          dateStr: report.eventDate,
          enterFormat: DEFAULT_DATE_FORMAT,
          exitFormat: EVENT_DATE_FORMAT,
        })
      : null;
    const eventEndDate = report?.eventEndDate
      ? getTimeAndDate({
          dateStr: report.eventEndDate,
          enterFormat: DEFAULT_DATE_FORMAT,
          exitFormat: EVENT_DATE_FORMAT,
        })
      : null;
    const eventStartLocalTime = report?.eventTime;
    const eventEndLocalTime = report?.eventEndTime;

    const publishDate =
      report?.reportSourceType === ReportSourceType.LOOKOUT
        ? report.modifiedDatetime
        : report?.publishedDatetime;

    return {
      timePublished: publishDate
        ? getTimeAndDate({
            dateStr: publishDate,
            exitFormat: TIME_PUBLISHED_FORMAT,
          })
        : null,
      eventDate:
        eventStartDate && eventEndDate ? `${eventStartDate} - ${eventEndDate}` : eventStartDate,
      eventLocalTime:
        eventStartLocalTime && eventEndLocalTime
          ? `${eventStartLocalTime} - ${eventEndLocalTime}`
          : eventStartLocalTime,
      sourceDate: null,
    };
  }, [report]);

  const markerIcon = useMemo(() => mapCategories(categories[0]).imgSrc, [categories]);

  const reportCountry = useMemo(() => getReportCountriesNames(report?.country || []), [report]);

  useEffect(() => {
    if (report) {
      dispatch(
        markReportAsOpened({
          reportId: report.reportId,
          reportSourceType: report.reportSourceType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => closeModal()}
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      matchContentSize
      overlayPosition={{
        top: HEADER_HEIGHT,
        right: '0',
        left: 'auto',
      }}
      overlayClassName="slide-from-right"
      closeTimeoutMS={300}
    >
      {report && (
        <DetailsContainer>
          <DetailsHeader>
            <HeaderTopBar>
              <Paragraph
                type="normal"
                color={colors.secondaryText}
              >
                {`Published on ${dateFormat.timePublished}`}
              </Paragraph>
              <CloseIcon onClick={closeModal}>
                <Icon name="Close" />
              </CloseIcon>
            </HeaderTopBar>
            <ReportTitle>
              <Heading type="h2">
                <Country>{reportCountry} | </Country>
                {report.reportTitle}
              </Heading>
              <TitleSource>
                {report.reportSourceType === ReportSourceType.LOOKOUT ? (
                  <Icon
                    name="MicrochipAi"
                    size={54}
                    color={colors.active}
                  />
                ) : (
                  <Icon
                    name="Person"
                    size={54}
                    color={colors.active}
                  />
                )}
              </TitleSource>
            </ReportTitle>
          </DetailsHeader>
          <MetaSection
            country={reportCountry}
            locationAddress={report.location[0].address}
            eventLocalTime={dateFormat.eventLocalTime}
            eventDate={dateFormat.eventDate!}
            categories={categories}
            postType={report.reportType}
            impact={report.impact}
            dead={report.victims.dead}
            wounded={report.victims.wounded}
            hostages={report.victims.hostages}
            missing={report.victims.missing}
            arrested={report.victims.arrested}
          />
          <ReportContent>
            <ReportContentColumn>
              <Subheadline
                type="normal"
                color={colors.secondaryText}
              >
                {report.content}
              </Subheadline>
              <ReportMap>
                <Map
                  key={report.reportId}
                  ref={setMapRef}
                  viewState={{
                    longitude: report.location[0].longitude,
                    latitude: report.location[0].latitude,
                    zoom: 10,
                  }}
                >
                  <Marker
                    longitude={report.location[0].longitude}
                    latitude={report.location[0].latitude}
                    anchor="bottom"
                  >
                    {markerIcon && (
                      <NextImage
                        src={markerIcon}
                        alt="map-icon"
                        width={40}
                        height={46}
                      />
                    )}
                  </Marker>
                  <MapGeofeatures geofeatures={geofeaturesToDisplay} />
                </Map>
              </ReportMap>
            </ReportContentColumn>
            <ReportContentColumn>
              <ReportSourceTitle>
                <Paragraph
                  type="normal"
                  color={colors.secondaryText}
                >
                  Sources
                </Paragraph>
              </ReportSourceTitle>
              {report.source.map((source, index) => (
                <Source
                  key={index}
                  link={source.url}
                  publicationDate={dateFormat.sourceDate ?? ''}
                  score={ReportScore.UNKNOWN}
                />
              ))}
            </ReportContentColumn>
          </ReportContent>
        </DetailsContainer>
      )}
    </Modal>
  );
};
